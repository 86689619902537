<template>
  <div class="home">
    <Hero>
      <template v-slot:hero>
        <section id="hero" class="hero">
          <div class="info d-flex align-items-center">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-8 text-center">
                  <h2 data-aos="fade-down">
                    <br />
                    <span>Blitar Putra Energi</span>
                  </h2>
                  <p data-aos="fade-up">
                    <!-- mengelola Sumber Daya dengan teknologi. -->
                    {{ $t("name") }} <br />
                    {{ $t("home.headlines.subtitle") }} <br />
                  </p>
                  <!-- <a data-aos="fade-up" data-aos-delay="200" href="#get-started" class="btn-get-started">Get Started</a> -->
                </div>
              </div>
            </div>
          </div>

          <div
            id="hero-carousel"
            class="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="5000"
          >
            <div
              class="carousel-item active headline"
              style="background-image: url(assets/img/siapa-kami.jpg)"
            ></div>
          </div>

          <!-- <img :src="jumbotronImg" alt=""> -->
        </section>
      </template>
    </Hero>

    <Main>
      <template v-slot:main>
        <section id="get-started" class="constructions">
          <div class="container" data-aos="fade-up">
            <div class="section-header">
              <h2>{{ $t("home.about.title") }}</h2>
              <p class="mt-3">
                {{ $t("home.about.desc1") }}
              </p>
              <p class="mt-3">
                {{ $t("home.about.desc2") }}
              </p>
              <p class="mt-3">
                {{ $t("home.about.desc3") }}
              </p>
              <p class="mt-3">
                {{ $t("home.about.desc4") }}
              </p>
            </div>
          </div>
        </section>

        <section id="services" class="services section-bg">
          <div class="container" data-aos="fade-up">
            <div class="section-header">
              <h2>{{ $t("home.projects.title") }}</h2>
            </div>

            <div
              class="portfolio-isotope"
              data-portfolio-filter="*"
              data-portfolio-layout="masonry"
              data-portfolio-sort="original-order"
            >
              <div class="row gy-4" data-aos="fade-up" data-aos-delay="200">
                <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                  <div class="service-item position-relative">
                    <!-- <div class="icon">
                      <i class="fa-solid fa-mountain-city"></i>
                    </div> -->
                    <h3>{{ $t("home.projects.sec1.title") }}</h3>
                    <p>
                      {{ $t("home.projects.sec1.desc") }}
                    </p>
                    <router-link
                      to="/green-industrial-integrated-plant-area"
                      class="readmore stretched-link"
                      >{{ $t("home.projects.sec1.button") }}
                      <i class="bi bi-arrow-right"></i
                    ></router-link>
                  </div>
                </div>

                <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                  <div class="service-item position-relative">
                    <h3>{{ $t("home.projects.sec2.title") }}</h3>
                    <!-- <p>
                      {{ $t("home.projects.sec1.desc") }}
                    </p> -->
                    <router-link
                      to="/dokumentasi/media/plan-project.mp4"
                      alt=""
                    >
                      <img
                        src="../assets/img/dokumentasi/video-cover.jpg"
                        class="img-fluid"
                      />
                    </router-link>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                  <div class="portfolio-content h-100">
                    <a
                      href="assets/img/ourproject/flowdiagram_refinery.jpeg"
                      alt=""
                    >
                      <img
                        src="assets/img/ourproject/flowdiagram_refinery.jpeg"
                        data-gallery="our project"
                        class="img-fluid glightbox"
                        alt=""
                        data-glightbox="description: .custom-desc3; descPosition: right;"
                      />
                    </a>

                    <div class="glightbox-desc custom-desc3">
                      <p>
                        <b>{{ $t("home.projects.sec3.title") }}</b>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                  <div class="portfolio-content h-100">
                    <a
                      href="assets/img/ourproject/flowdiagram_petrochemical.jpeg"
                      alt=""
                    >
                      <img
                        src="assets/img/ourproject/flowdiagram_petrochemical.jpeg"
                        data-gallery="our project"
                        class="img-fluid glightbox"
                        alt=""
                        data-glightbox="description: .custom-desc4; descPosition: right;"
                      />
                    </a>

                    <div class="glightbox-desc custom-desc4">
                      <p>
                        <b>{{ $t("home.projects.sec4.title") }}</b>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                  <div class="portfolio-content h-100">
                    <a
                      href="assets/img/ourproject/productlist_refinery.jpeg"
                      alt=""
                    >
                      <img
                        src="assets/img/ourproject/productlist_refinery.jpeg"
                        data-gallery="our project"
                        class="img-fluid glightbox"
                        alt=""
                        data-glightbox="description: .custom-desc5; descPosition: right;"
                      />
                    </a>

                    <div class="glightbox-desc custom-desc5">
                      <p>
                        <b>{{ $t("home.projects.sec5.title") }}</b>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                  <div class="portfolio-content h-100">
                    <a
                      href="assets/img/ourproject/productlist_petrochemical.jpeg"
                      alt=""
                    >
                      <img
                        src="assets/img/ourproject/productlist_petrochemical.jpeg"
                        data-gallery="our project"
                        class="img-fluid glightbox"
                        alt=""
                        data-glightbox="description: .custom-desc6; descPosition: right;"
                      />
                    </a>

                    <div class="glightbox-desc custom-desc6">
                      <p>
                        <b>{{ $t("home.projects.sec6.title") }}</b>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                  <div class="portfolio-content h-100">
                    <a href="assets/img/ourproject/PRE RF SUMMARY-1.jpg" alt="">
                      <img
                        src="assets/img/ourproject/PRE RF SUMMARY-1.jpg"
                        data-gallery="our project"
                        class="img-fluid glightbox"
                        alt=""
                        data-glightbox="description: .custom-desc7; descPosition: right;"
                      />
                    </a>

                    <div class="glightbox-desc custom-desc7">
                      <p>
                        <b>{{ $t("home.projects.sec7.title") }}</b>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                  <div class="portfolio-content h-100">
                    <a href="assets/img/ourproject/PRE RF SUMMARY-2.jpg" alt="">
                      <img
                        src="assets/img/ourproject/PRE RF SUMMARY-2.jpg"
                        data-gallery="our project"
                        class="img-fluid glightbox"
                        alt=""
                        data-glightbox="description: .custom-desc8; descPosition: right;"
                      />
                    </a>

                    <div class="glightbox-desc custom-desc8">
                      <p>
                        <b>{{ $t("home.projects.sec8.title") }}</b>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                  <div class="portfolio-content h-100">
                    <a href="assets/img/ourproject/PRE RF SUMMARY-3.jpg" alt="">
                      <img
                        src="assets/img/ourproject/PRE RF SUMMARY-3.jpg"
                        data-gallery="our project"
                        class="img-fluid glightbox"
                        alt=""
                        data-glightbox="description: .custom-desc9; descPosition: right;"
                      />
                    </a>

                    <div class="glightbox-desc custom-desc9">
                      <p>
                        <b>{{ $t("home.projects.sec9.title") }}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="team" class="team">
          <div class="container" data-aos="fade-up">
            <div class="section-header">
              <h2>{{ $t("home.founders.title") }}</h2>
            </div>

            <div class="row gy-5">
              <div
                class="col-lg-4 col-md-6 member"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="member-img">
                  <!-- <img src="assets/img/team/team-1.jpg" class="img-fluid" alt=""> -->
                  <img
                    src="../assets/img/team/faridzaenuddin_2.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="member-info text-center">
                  <h4>Letjen TNI Purn H.Farid zainuddin</h4>
                  <span>{{ $t("home.founders.sec1.position") }}</span>
                  * {{ $t("home.founders.sec1.cv[0]") }} <br />
                  * {{ $t("home.founders.sec1.cv[1]") }}
                </div>
              </div>
              <!-- End Team Member -->

              <div
                class="col-lg-4 col-md-6 member"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="member-img">
                  <img
                    src="../assets/img/team/iqbalsulam.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="member-info text-center">
                  <h4>Ir.H.M.Iqbal Sullam</h4>
                  <span>{{ $t("home.founders.sec2.position") }}</span>
                  * {{ $t("home.founders.sec2.cv[0]") }}<br />
                  * {{ $t("home.founders.sec2.cv[1]") }}
                </div>
              </div>
              <!-- End Team Member -->

              <div
                class="col-lg-4 col-md-6 member"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div class="member-img">
                  <img
                    src="../assets/img/team/mtoha.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="member-info text-center">
                  <h4>H. Mohamad Toha</h4>
                  <span>{{ $t("home.founders.sec3.position") }}</span>
                  * {{ $t("home.founders.sec3.cv[0]") }}<br />
                  * {{ $t("home.founders.sec3.cv[1]") }} <br />
                  * {{ $t("home.founders.sec3.cv[2]") }} <br />
                  * {{ $t("home.founders.sec3.cv[3]") }}
                </div>
              </div>
              <!-- End Team Member -->
            </div>

            <div class="row gy-5">
              <div
                class="col-lg-4 col-md-6 member"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="member-img">
                  <!-- <img src="assets/img/team/team-1.jpg" class="img-fluid" alt=""> -->
                  <img
                    src="../assets/img/team/addymashut3.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="member-info text-center">
                  <h4>{{ $t("home.founders.sec7.name") }}</h4>
                  <span>{{ $t("home.founders.sec7.position") }}</span>
                  * {{ $t("home.founders.sec7.cv[0]") }} <br />
                </div>
              </div>
              <!-- End Team Member -->

              <div
                class="col-lg-4 col-md-6 member"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="member-img">
                  <img src="../assets/img/team/muhsinidrus2.jpeg" class="img-fluid" alt="" />
                </div>
                <div class="member-info text-center">
                  <h4>{{ $t("home.founders.sec5.name") }}</h4>
                  <span>{{ $t("home.founders.sec5.position") }}</span>
                  * {{ $t("home.founders.sec5.cv[0]") }}<br />
                </div>
              </div>
              <!-- End Team Member -->

              <div
                class="col-lg-4 col-md-6 member"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="member-img">
                  <img
                    src="../assets/img/team/imamdr.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="member-info text-center">
                  <h4>{{ $t("home.founders.sec6.name") }}</h4>
                  <span>{{ $t("home.founders.sec6.position") }}</span>
                  * {{ $t("home.founders.sec6.cv[0]") }}<br />
                </div>
              </div>
              <!-- End Team Member -->
            </div>

            <div class="row gy-5">
              <div
                class="col-lg-4 col-md-6 member"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="member-img">
                  <img
                    src="../assets/img/team/bambangirawan.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="member-info text-center">
                  <h4>{{ $t("home.founders.sec8.name") }}</h4>
                  <span>{{ $t("home.founders.sec8.position") }}</span>
                  * {{ $t("home.founders.sec8.cv[0]") }}<br />
                </div>
              </div>
              <!-- End Team Member -->

              <div
                class="col-lg-4 col-md-6 member"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="member-img">
                  <img
                    src="../assets/img/team/tonihartono2.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="member-info text-center">
                  <h4>{{ $t("home.founders.sec9.name") }}</h4>
                  <span>{{ $t("home.founders.sec9.position") }}</span>
                  * {{ $t("home.founders.sec9.cv[0]") }}<br />
                </div>
              </div>
              <!-- End Team Member -->

              <div
                class="col-lg-4 col-md-6 member"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="member-img">
                  <img
                    src="../assets/img/team/iskandar.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="member-info text-center">
                  <h4>{{ $t("home.founders.sec4.name") }}</h4>
                  <span>{{ $t("home.founders.sec4.position") }}</span>
                  * {{ $t("home.founders.sec4.cv[0]") }}<br />
                  * {{ $t("home.founders.sec4.cv[1]") }}
                </div>
              </div>
              <!-- End Team Member -->
            </div>

            <div class="row gy-5">
              <div
                class="col-lg-4 col-md-6 member"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="member-img">
                  <img
                    src="../assets/img/team/aditya.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="member-info text-center">
                  <h4>{{ $t("home.founders.sec10.name") }}</h4>
                  <span>{{ $t("home.founders.sec10.position") }}</span>
                  * {{ $t("home.founders.sec10.cv[0]") }}<br />
                  * {{ $t("home.founders.sec10.cv[1]") }}
                </div>
              </div>
              <div
                class="col-lg-4 col-md-6 member"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="member-img">
                  <img
                    src="../assets/img/team/akhmadyani.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="member-info text-center">
                  <h4>{{ $t("home.founders.sec11.name") }}</h4>
                  <span>{{ $t("home.founders.sec12.position") }}</span>
                  * {{ $t("home.founders.sec11.cv[0]") }}<br />
                  * {{ $t("home.founders.sec11.cv[1]") }}
                </div>
              </div>
              <div
                class="col-lg-4 col-md-6 member"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="member-img">
                  <img
                    src="../assets/img/team/fazilsenel.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="member-info text-center">
                  <h4>{{ $t("home.founders.sec12.name") }}</h4>
                  <span>{{ $t("home.founders.sec12.position") }}</span>
                  * {{ $t("home.founders.sec12.cv[0]") }}<br />
                  * {{ $t("home.founders.sec12.cv[1]") }}
                </div>
              </div>
              <!-- End Team Member -->
            </div>
                        
          </div>
        </section>
        <!-- End Our Team Section -->

        <section id="projects" class="projects section-bg">
          <div class="container" data-aos="fade-up">
            <div class="section-header">
              <h2>{{ $t("home.sitelocation.title") }}</h2>
            </div>

            <div
              class="portfolio-isotope"
              data-portfolio-filter="*"
              data-portfolio-layout="masonry"
              data-portfolio-sort="original-order"
            >
              <div
                class="row gy-4 portfolio-container"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                  <div class="portfolio-content h-100">
                    <a href="assets/img/sitelocation.jpeg" alt="">
                      <img
                        src="assets/img/sitelocation.jpeg"
                        data-gallery="dokumentasi"
                        class="img-fluid glightbox"
                        alt=""
                        data-glightbox="title: Docs. PT. Blitar Putra Energi; description: .custom-descx1; descPosition: right;"
                      />
                    </a>

                    <div class="glightbox-desc custom-descx1">
                      <p>
                        {{ $t("home.sitelocation.doc1") }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                  <div class="portfolio-content h-100">
                    <router-link
                      to="/dokumentasi/media/sitelocation.mp4"
                      alt=""
                    >
                      <img
                        src="../assets/img/dokumentasi/bse-site-location.jpg"
                        class="img-fluid"
                      />
                    </router-link>
                    <div class="glightbox-desc custom-desc4">
                      <p>-</p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                  <div class="portfolio-content h-100">
                    <a href="assets/img/koordinatpetalokasiproyek.jpeg" alt="">
                      <img
                        src="assets/img/koordinatpetalokasiproyek.jpeg"
                        data-gallery="dokumentasi"
                        class="img-fluid glightbox"
                        alt=""
                        data-glightbox="title: Docs. PT. Blitar Putra Energi; description: .custom-descx3; descPosition: right;"
                      />
                    </a>

                    <div class="glightbox-desc custom-descx3">
                      <p>
                        <b>{{ $t("home.sitelocation.doc3") }}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Projects Container -->
            </div>

            <div
              class="portfolio-isotope"
              data-portfolio-filter="*"
              data-portfolio-layout="masonry"
              data-portfolio-sort="original-order"
            >
              <div
                class="row gy-4 portfolio-container"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                  <div class="portfolio-content h-100">
                    <a href="assets/img/sitelocation_2.jpeg" alt="">
                      <img
                        src="assets/img/sitelocation_2.jpeg"
                        data-gallery="dokumentasi"
                        class="img-fluid glightbox"
                        alt=""
                        data-glightbox="title: Docs. PT. Blitar Putra Energi; description: .custom-descx4; descPosition: right;"
                      />
                    </a>

                    <div class="glightbox-desc custom-descx4">
                      <p>
                        {{ $t("home.sitelocation.doc4") }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                  <div class="portfolio-content h-100">
                    <a href="assets/img/bpesiteoffice plan.jpeg" alt="">
                      <img
                        src="assets/img/bpesiteoffice plan.jpeg"
                        data-gallery="dokumentasi"
                        class="img-fluid glightbox"
                        alt=""
                        data-glightbox="title: Docs. PT. Blitar Putra Energi; description: .custom-descx5; descPosition: right;"
                      />
                    </a>

                    <div class="glightbox-desc custom-descx5">
                      <p>
                        {{ $t("home.sitelocation.doc5") }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                  <div class="portfolio-content h-100">
                    <a href="assets/img/ijin-lokasi-1500-ha.jpg" alt="">
                      <img
                        src="assets/img/ijin-lokasi-1500-ha.jpg"
                        data-gallery="dokumentasi"
                        class="img-fluid glightbox"
                        alt=""
                        data-glightbox="title: Docs. PT. Blitar Putra Energi; description: .custom-descx6; descPosition: right;"
                      />
                    </a>

                    <div class="glightbox-desc custom-descx6">
                      <p>
                        {{ $t("home.sitelocation.doc6") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Projects Container -->
            </div>
          </div>
        </section>

        <!-- ======= Constructions Section ======= -->
        <section id="constructions" class="constructions">
          <div class="container" data-aos="fade-up">
            <div class="section-header">
              <h2>{{ $t("home.partners.title") }}</h2>
              <!-- <p>
                Nulla dolorum nulla nesciunt rerum facere sed ut inventore quam
                porro nihil id ratione ea sunt quis dolorem dolore earum
              </p> -->
            </div>

            <div class="row">
              <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                <div class="card-item">
                  <div class="row">
                    <div class="col-xl-6">
                      <a
                        href="http://www.kmkglobalenergy.com"
                        class="readmore stretched-link"
                      ></a>
                      <div
                        class="card-bg"
                        style="
                          background-image: url(assets/img/support/kmkglobalenergy.png);
                        "
                      ></div>
                    </div>
                    <div class="col-xl-6 d-flex align-items-center">
                      <div class="card-body">
                        <h4 class="card-title">
                          Investors and Crude oil supply .
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Card Item -->

              <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
                <div class="card-item">
                  <div class="row">
                    <div class="col-xl-6">
                      <a
                        href="https://www.jgc-indonesia.com/"
                        class="readmore stretched-link"
                      ></a>
                      <div
                        class="card-bg"
                        style="
                          background-image: url(assets/img/support/jgc.png);
                        "
                      ></div>
                    </div>
                    <div class="col-xl-6 d-flex align-items-center">
                      <div class="card-body">
                        <h4 class="card-title">EPC work</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Card Item -->

              <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
                <div class="card-item">
                  <div class="row">
                    <div class="col-xl-6">
                      <a
                        href="https://www.ikpt.com/"
                        class="readmore stretched-link"
                      ></a>
                      <div
                        class="card-bg"
                        style="
                          background-image: url(assets/img/support/ikpt.png);
                        "
                      ></div>
                    </div>
                    <div class="col-xl-6 d-flex align-items-center">
                      <div class="card-body">
                        <h4 class="card-title">EPC work</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Card Item -->
            </div>

            <div class="row">
              <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                <div class="card-item">
                  <div class="row">
                    <div class="col-xl-6">
                      <a
                        href="https://www.wika.co.id/"
                        class="readmore stretched-link"
                      ></a>
                      <div
                        class="card-bg"
                        style="
                          background-image: url(assets/img/support/wika.png);
                        "
                      ></div>
                    </div>
                    <div class="col-xl-6 d-flex align-items-center">
                      <div class="card-body">
                        <h4 class="card-title">EPC work</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Card Item -->

              <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
                <div class="card-item">
                  <div class="row">
                    <div class="col-xl-6">
                      <a
                        href="http://www.hacacagroup.com/"
                        class="readmore stretched-link"
                      ></a>
                      <div
                        class="card-bg"
                        style="
                          background-image: url(assets/img/support/hacaca.png);
                        "
                      ></div>
                    </div>
                    <div class="col-xl-6 d-flex align-items-center">
                      <div class="card-body">
                        <h4 class="card-title">Shipment & Logistic</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Card Item -->
            </div>
          </div>
        </section>
        <!-- End Constructions Section -->
      </template>
    </Main>
  </div>
</template>

<script>
// @ is an alias to /src

import Hero from "@/layouts/HomeLayout.vue";
import Main from "@/layouts/MainLayout.vue";
import jumbotronImg from "../../public/assets/img/projects/construction-1.jpg";
import css from "../assets/main-custom.css";

export default {
  name: "HomeView",
  components: {
    Hero,
    Main,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      jumbotron: jumbotronImg,
      greenarea:
        "Merupakan kawasan industri hijau terintegrasi dengan luas wilayah 1500ha yang berbatasan langsung dengan laut....",
      projects: [],
    };
  },
  mounted() {
    this.lightbox = GLightbox({
      selector: ".glightbox",
      touchNavigation: true,
    });
  },
};
</script>

<style scoped></style>
