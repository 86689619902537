<template>
    <div>
        <slot name="hero"></slot>
        <slot name="main"></slot>
    </div>
</template>
<script>
    export default {

    }
</script>
<style>

</style>