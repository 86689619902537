import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {createI18n} from 'vue-i18n'
import messages from './localization'
import {CoolLightBox} from 'vue-cool-lightbox'

import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export const i18n = createI18n({
    // legacy: false,
    locale : store.getters.getLanguage,
    fallbackLocale: 'en',
    globalInjection: true,
    messages
})

const app = createApp(App)
app.use(store)
app.use(router)
app.use(i18n)
app.use(CoolLightBox)
app.mount('#app')
