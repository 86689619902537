<template>
    <div>
        <Layout>
            

            <template v-slot:main>
                <div>

                    <section id="projects" class="projects">
                        <div class="container " data-aos="fade-up">

                            <div class="section-header">
                                <h2>{{ $t('business.dokumentasi.title') }}</h2>
                            </div>

                            <div class="col-lg-12">
                                <div class=" text-center">

                                <video-player
                                :poster="require('@/assets/img/dokumentasi/video-cover.jpg')"
                                :src="require('@/assets/vidio/'+this.$route.params.media+'')"
                                controls
                                :loop="true"
                                :volume="0.6"
                                :autoplay="true"
                                :width=600

                                />

                                </div>
                            </div>


                        </div>
                    </section>


                </div>
            </template>
        </Layout>
    </div>
</template>
<script>
    import Layout from '../layouts/MainLayout.vue';
    import { VideoPlayer } from '@videojs-player/vue'
    import {video} from '../assets/vidio/plan-project.mp4';
    import 'video.js/dist/video-js.css'
  
    export default {
        name: "media",
        components: {
            Layout, VideoPlayer
        },

        data(){
            return{
                // videoOptions: {
                //     autoplay: true,
                //     controls: true,
                //     sources: [
                //     {
                //         src:
                //         '@/assets/vidio/plan-project.mp4',
                //         type: 'video/mp4'
                //     }
                //     ]
                // }
            }
        },
        // mounted() {
        //     this.media = '<%BASE_URL%>assets/vidio/'+this.$route.params.media;
        //     console.log(this.media);
        // },


    }
</script>
<style>
@import 'video.js/dist/video-js.css';
    .img-blog {
        object-fit: contain;
        width: 100%;
        float: left;
        margin-bottom: 20px;

    }

    @media (min-width: 767.98px) {
        .img-blog {
            object-fit: contain;
            width: 200px;
            float: right;
        }
    }
</style>