<template>
  <div>
    <Layout>
      <template v-slot:hero>
        <div>
          <section id="hero" class="hero">
            <div class="info d-flex align-items-center">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-6 text-center">
                    <h2 data-aos="fade-down">
                      {{ $t("business.dokumentasi.title") }}
                    </h2>
                    <p data-aos="fade-up">
                      {{ $t("business.dokumentasi.sub") }}
                    </p>
                    <!-- <a data-aos="fade-up" data-aos-delay="200" href="#dokumentasi"
                                            class="btn-get-started">Berita Investor</a> -->
                  </div>
                </div>
              </div>
            </div>

            <div
              id="hero-carousel"
              class="carousel slide"
              data-bs-ride="carousel"
              data-bs-interval="5000"
            >
              <div
                class="carousel-item active"
                style="background-image: url(assets/img/bg/bg1.jpg)"
              ></div>
              <div
                class="carousel-item"
                style="background-image: url(assets/img/bg/bg2.jpg)"
              ></div>
              <div
                class="carousel-item"
                style="background-image: url(assets/img/bg/bg3.jpg)"
              ></div>
              <div
                class="carousel-item"
                style="background-image: url(assets/img/bg/bg4.jpg)"
              ></div>

              <a
                class="carousel-control-prev"
                href="#hero-carousel"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                ></span>
              </a>

              <a
                class="carousel-control-next"
                href="#hero-carousel"
                role="button"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                ></span>
              </a>
            </div>
          </section>
        </div>
      </template>

      <template v-slot:main>
        <div>
          <section id="projects" class="projects">
            <div class="container" data-aos="fade-up">
              <div class="section-header">
                <h2>{{ $t("business.dokumentasi.title") }}</h2>
              </div>

              <div
                class="portfolio-isotope"
                data-portfolio-filter="*"
                data-portfolio-layout="masonry"
                data-portfolio-sort="original-order"
              >
                <div
                  class="row gy-4 portfolio-container"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <!-- <div class="col-lg-4 col-md-6 portfolio-item filter-remodeling" v-for="documentation in documentations" :key="documentation">
                                        <div class="portfolio-content h-100">
                                            
                                            <a href="../assets/img/dokumentasi/lahan.jpg" alt="" > 
                                                <img src="../assets/img/dokumentasi/lahan.jpg" data-gallery="dokumentasi" class="img-fluid glightbox"  alt="" data-glightbox="title: Docs. PT. Blitar Putra Energi; description: .custom-desc1; descPosition: right;">
                                            </a>

                                            <div class="glightbox-desc custom-desc1">

                                                <p>
                                                    {{ documentation.desc }}
                                                </p>
                                               
                                            </div>
                                            
                                           
                                        </div>
                                    </div> -->

                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <a
                        href="../assets/img/dokumentasi/peta-lokasi.jpg"
                        alt=""
                      >
                        <img
                          src="../assets/img/dokumentasi/peta-lokasi.jpg"
                          data-gallery="dokumentasi"
                          class="img-fluid glightbox"
                          alt=""
                          data-glightbox="title: Docs. PT. Blitar Putra Energi; description: .custom-desc1; descPosition: right;"
                        />
                      </a>

                      <div class="glightbox-desc custom-desc1">
                        <p>
                          {{ $t("documentation.doc1") }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <a href="../assets/img/dokumentasi/lahan.jpg" alt="">
                        <img
                          src="../assets/img/dokumentasi/lahan.jpg"
                          data-gallery="dokumentasi"
                          class="img-fluid glightbox"
                          alt=""
                          data-glightbox="title: Docs. PT. Blitar Putra Energi; description: .custom-desc2; descPosition: right;"
                        />
                      </a>

                      <div class="glightbox-desc custom-desc2">
                        <p>
                          {{ $t("documentation.doc2") }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <a href="../assets/img/dokumentasi/muspika.jpg" alt="">
                        <img
                          src="../assets/img/dokumentasi/muspika.jpg"
                          data-gallery="dokumentasi"
                          class="img-fluid glightbox"
                          alt=""
                          data-glightbox="title: Docs. PT. Blitar Putra Energi; description: .custom-desc3; descPosition: right;"
                        />
                      </a>
                      <div class="glightbox-desc custom-desc3">
                        <p>
                          {{ $t("documentation.doc3") }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <a
                        href="../assets/img/dokumentasi/fisit_project.jpeg"
                        alt=""
                      >
                        <img
                          src="../assets/img/dokumentasi/fisit_project.jpeg"
                          data-gallery="dokumentasi"
                          class="img-fluid glightbox"
                          alt=""
                          data-glightbox="title: Docs. PT. Blitar Putra Energi; description: .custom-desc4; descPosition: right;"
                        />
                      </a>
                      <div class="glightbox-desc custom-desc4">
                        <p>
                          {{ $t("documentation.doc4") }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <router-link
                        to="/dokumentasi/media/plan-project.mp4"
                        alt=""
                      >
                        <img
                          src="../assets/img/dokumentasi/video-cover.jpg"
                          class="img-fluid"
                        />
                      </router-link>
                      <div class="glightbox-desc custom-desc4">
                        <p>-</p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <a href="../assets/img/dokumentasi/audiensi.jpg" alt="">
                        <img
                          src="../assets/img/dokumentasi/audiensi.jpg"
                          data-gallery="dokumentasi"
                          class="img-fluid glightbox"
                          alt=""
                          data-glightbox="title: Docs. PT. Blitar Putra Energi; description: .custom-desc5; descPosition: right;"
                        />
                      </a>
                      <div class="glightbox-desc custom-desc5">
                        <p>
                          {{ $t("documentation.doc5") }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <a
                        href="../assets/img/dokumentasi/crude-oil-ability-letter-KMK-Global-energy.jpg"
                        alt=""
                      >
                        <img
                          src="../assets/img/dokumentasi/crude-oil-ability-letter-KMK-Global-energy.jpg"
                          data-gallery="dokumentasi"
                          class="img-fluid glightbox"
                          alt=""
                          data-glightbox="title: Docs. PT. Blitar Putra Energi; description: .custom-desc6; descPosition: right;"
                        />
                      </a>
                      <div class="glightbox-desc custom-desc6">
                        <p>
                          {{ $t("documentation.doc6") }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <a
                        href="../assets/img/dokumentasi/MOU-signed-with-UEA.jpg"
                        alt=""
                      >
                        <img
                          src="../assets/img/dokumentasi/MOU-signed-with-UEA.jpg"
                          data-gallery="dokumentasi"
                          class="img-fluid glightbox"
                          alt=""
                          data-glightbox="title: Docs. PT. Blitar Putra Energi; description: .custom-desc7; descPosition: right;"
                        />
                      </a>
                      <div class="glightbox-desc custom-desc7">
                        <p>
                          {{ $t("documentation.doc7") }}
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <a
                        href="../assets/img/dokumentasi/Land-permit-page-0001.jpg"
                        alt=""
                      >
                        <img
                          src="../assets/img/dokumentasi/Land-permit-page-0001.jpg"
                          data-gallery="dokumentasi"
                          class="img-fluid glightbox"
                          alt=""
                          data-glightbox="title: Docs. PT. Blitar Putra Energi; description: .custom-desc12; descPosition: right;"
                        />
                      </a>
                      <div class="glightbox-desc custom-desc12">
                        <p>
                          {{ $t("documentation.doc9") }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <a href="../assets/img/dokumentasi/audiensi2.jpg" alt="">
                        <img
                          src="../assets/img/dokumentasi/audiensi2.jpg"
                          data-gallery="dokumentasi"
                          class="img-fluid glightbox"
                          alt=""
                          data-glightbox="title: Docs. PT. Blitar Putra Energi; description: .custom-desc8; descPosition: right;"
                        />
                      </a>
                      <div class="glightbox-desc custom-desc8">
                        <p>
                          {{ $t("documentation.doc8") }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- <CoolLightBox 
                                        :items="documentations" 
                                        :index="index" 
                                        @close="index = null">
                                    </CoolLightBox>

                                    <div class="images-wrapper">
                                        <div class="image" 
                                            v-for="(image, imageIndex) in documentations" :key="imageIndex"
                                            @click="index = imageIndex"
                                            :style="{ backgroundImage: 'url(' + image.src + ')' }"></div>
                                    </div> -->



                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <router-link
                        to="/dokumentasi/media/bse-site-location.mp4"
                        alt=""
                      >
                        <img
                          src="../assets/img/dokumentasi/bse-site-location.jpg"
                          class="img-fluid"
                        />
                      </router-link>
                      <div class="glightbox-desc custom-desc4">
                        <p>-</p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <router-link
                        to="/dokumentasi/media/a-new-petrochemical-and-oil-refinery-plant-have-to-be-established-soon.mp4"
                        alt=""
                      >
                        <img
                          src="../assets/img/dokumentasi/president-speach.jpg"
                          class="img-fluid"
                        />
                      </router-link>
                      <div class="glightbox-desc custom-desc4">
                        <p>-</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Projects Container -->
              </div>
            </div>
          </section>
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
import { CoolLightBox } from "vue-cool-lightbox";
import Layout from "../layouts/MainLayout.vue";
import "glightbox/dist/css/glightbox.css";
import "glightbox/dist/js/glightbox.js";
import GLightbox from "glightbox";
import { toRaw } from "vue";
import { mapGetters } from "vuex";
export default {
  name: "beritainvestor",
  components: {
    Layout,
    CoolLightBox,
  },
  data() {
    return {
      language: null,
      documentations: [],
    };
  },

  computed: {
    ...mapGetters({
      language: "getLanguage",
    }),
    cekLanguage() {
      return this.$store.getters.getLanguage;
    },
  },

  mounted() {
    this.lightbox = GLightbox({
      selector: ".glightbox",
      touchNavigation: true,
    });

    this.language = this.$i18n.locale;
    console.log("current language " + this.language);

    if (this.language == "en") {
      this.documentations = this.$i18n.messages.en.documentation.data;
    } else {
      this.documentations = this.$i18n.messages.id.documentation.data;
    }

    console.log(toRaw(this.documentations));
  },

  methods: {
    setDokumentasi(lang) {
      if (lang == "en") {
        this.documentations = this.$i18n.messages.en.documentation.data;
      } else {
        this.documentations = this.$i18n.messages.id.documentation.data;
      }
      console.log(this.documentations);
    },
  },

  watch: {
    cekLanguage(n, o) {
      this.setDokumentasi(n);
    },
  },
};
</script>
<style>
.img-blog {
  object-fit: contain;
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

@media (min-width: 767.98px) {
  .img-blog {
    object-fit: contain;
    width: 200px;
    float: right;
  }
}
</style>
