<template>
  <div>
    <Layout>
      <template v-slot:hero>
        <div>
          <section id="hero" class="hero">
            <div class="info d-flex align-items-center">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-6 text-center">
                    <h2 data-aos="fade-down">{{ $t('visimisi.title') }}</h2>
                  </div>
                </div>
              </div>
            </div>

            <div id="hero-carousel" class="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">

<div class="carousel-item active"
    style="background-image: url(assets/img/bg/bg1.jpg)">
</div>
<div class="carousel-item"
    style="background-image: url(assets/img/bg/bg2.jpg)"></div>
<div class="carousel-item"
    style="background-image: url(assets/img/bg/bg3.jpg)"></div>
<div class="carousel-item"
    style="background-image: url(assets/img/bg/bg4.jpg)"></div>

<a class="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
    <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
</a>

<a class="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
    <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
</a>

</div>
          </section>

          <section id="constructions" class="constructions">
            <div class="container" data-aos="fade-up">


              <div class="row mt-5">
                <div class="col-lg">
                  <h5 class="text-center">{{ $t('visimisi.visi.title')}}</h5>
                  <p>
                    {{ $t('visimisi.visi.desc')}}
                  </p>
                </div>
                <div class="col-lg">
                  <h5 class="text-center">{{ $t('visimisi.misi.title')}}</h5>
                  <p>
                    {{ $t('visimisi.misi.desc')}}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
  import Layout from '../layouts/MainLayout.vue'
  export default {
    name: "visionmission",
    components: {
      Layout
    }
  }
</script>
<style>

</style>