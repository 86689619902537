<template>
  <div>
    <Layout>
      <template v-slot:hero>
        <div>
          <section id="hero" class="hero">
            <div class="info d-flex align-items-center">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-6 text-center">
                    <h2 data-aos="fade-down">{{ $t("investor.title") }}</h2>
                    <p data-aos="fade-up">{{ $t("investor.subtitle") }}</p>
                    <a
                      data-aos="fade-up"
                      data-aos-delay="200"
                      href="#about"
                      class="btn-get-started"
                    >
                      {{ $t("investor.button") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="hero-carousel"
              class="carousel slide"
              data-bs-ride="carousel"
              data-bs-interval="5000"
            >
              <div
                class="carousel-item active"
                style="background-image: url(assets/img/bg/bg1.jpg)"
              ></div>
              <div
                class="carousel-item"
                style="background-image: url(assets/img/bg/bg2.jpg)"
              ></div>
              <div
                class="carousel-item"
                style="background-image: url(assets/img/bg/bg3.jpg)"
              ></div>
              <div
                class="carousel-item"
                style="background-image: url(assets/img/bg/bg4.jpg)"
              ></div>

              <a
                class="carousel-control-prev"
                href="#hero-carousel"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                ></span>
              </a>

              <a
                class="carousel-control-next"
                href="#hero-carousel"
                role="button"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                ></span>
              </a>
            </div>
          </section>
        </div>
      </template>

      <template v-slot:main>
        <div>
          <section id="about" class="services">
            <div class="container" data-aos="fade-up">
              <div class="section-header">
                <h2>{{ $t("investor.who.title") }}</h2>
                <p>
                  {{ $t("investor.who.desc") }}
                </p>
              </div>
            </div>
          </section>

          <section
            id="whyinvestinblitarputraenergi"
            class="services section-bg"
          >
            <div class="container" data-aos="fade-up">
              <div class="section-header">
                <h2>{{ $t("investor.why.title") }}</h2>
                <p>
                  {{ $t("investor.why.desc") }}
                </p>
              </div>
            </div>
          </section>

          <section id="latestnews" class="service Get-started">
            <div class="container" data-aos="fade-up" data-aos-delay="100">
              <h2 class="mb-5 text-dark">{{ $t("investor.news.title") }}</h2>

              <div class="row gy-4 posts-list">
                <div class="col-xl-4 col-md-6">
                  <div class="post-item position-relative h-100">
                    <div class="post-img position-relative overflow-hidden">
                      <img
                        src="assets/img/persetujuan-lahan.jpg"
                        class="img-fluid"
                        alt=""
                      />
                      <span class="post-date">{{
                        $t("investor.news.news1.date")
                      }}</span>
                    </div>

                    <div class="post-content d-flex flex-column">
                      <div class="meta d-flex align-items-center">
                        <div class="d-flex align-items-center">
                          <i class="bi bi-person"></i>
                          <span class="ps-2">{{
                            $t("investor.news.news1.author")
                          }}</span>
                        </div>
                        <!-- <span class="px-3 text-black-50">/</span>
                                                <div class="d-flex align-items-center">
                                                    <i class="bi bi-folder2"></i> <span class="ps-2">2023-05-28</span>
                                                </div> -->
                      </div>

                      <p>
                        {{ $t("investor.news.news1.title") }}
                      </p>

                      <hr />

                      <router-link
                        to="berita/1/pt-blitar-putra-energi-bpe-telah-memperoleh-ijin-lokasi-dari-pemkab-blitar-tahun-2020"
                        class="readmore stretched-link"
                      >
                        <span>{{ $t("investor.news.news1.button") }}</span
                        ><i class="bi bi-arrow-right"></i>
                      </router-link>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6">
                  <div class="post-item position-relative h-100">
                    <div class="post-img position-relative overflow-hidden">
                      <img
                        src="assets/img/persetujuan-lahan.jpg"
                        class="img-fluid"
                        alt=""
                      />
                      <span class="post-date">{{
                        $t("investor.news.news2.date")
                      }}</span>
                    </div>

                    <div class="post-content d-flex flex-column">
                      <div class="meta d-flex align-items-center">
                        <div class="d-flex align-items-center">
                          <i class="bi bi-person"></i>
                          <span class="ps-2">{{
                            $t("investor.news.news2.author")
                          }}</span>
                        </div>
                        <!-- <span class="px-3 text-black-50">/</span>
                                                <div class="d-flex align-items-center">
                                                    <i class="bi bi-folder2"></i> <span class="ps-2">2023-05-28</span>
                                                </div> -->
                      </div>

                      <p>
                        {{ $t("investor.news.news2.title") }}
                      </p>

                      <hr />

                      <router-link
                        to="berita/2/site-location"
                        class="readmore stretched-link"
                      >
                        <span>{{ $t("investor.news.news2.button") }}</span
                        ><i class="bi bi-arrow-right"></i>
                      </router-link>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6">
                  <div class="post-item position-relative h-100">
                    <div class="post-img position-relative overflow-hidden">
                      <img
                        src="assets/img/persetujuan-lahan.jpg"
                        class="img-fluid"
                        alt=""
                      />
                      <span class="post-date">{{
                        $t("investor.news.news3.date")
                      }}</span>
                    </div>

                    <div class="post-content d-flex flex-column">
                      <div class="meta d-flex align-items-center">
                        <div class="d-flex align-items-center">
                          <i class="bi bi-person"></i>
                          <span class="ps-2">{{
                            $t("investor.news.news3.author")
                          }}</span>
                        </div>
                        <!-- <span class="px-3 text-black-50">/</span>
                                                <div class="d-flex align-items-center">
                                                    <i class="bi bi-folder2"></i> <span class="ps-2">2023-05-28</span>
                                                </div> -->
                      </div>

                      <p>
                        {{ $t("investor.news.news3.title") }}
                      </p>

                      <hr />

                      <router-link
                        to="berita/3/memorandum-of-understanding"
                        class="readmore stretched-link"
                      >
                        <span>{{ $t("investor.news.news3.button") }}</span
                        ><i class="bi bi-arrow-right"></i>
                      </router-link>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6">
                  <div class="post-item position-relative h-100">
                    <div class="post-img position-relative overflow-hidden">
                      <img
                        src="assets/img/persetujuan-lahan.jpg"
                        class="img-fluid"
                        alt=""
                      />
                      <span class="post-date">{{
                        $t("investor.news.news4.date")
                      }}</span>
                    </div>

                    <div class="post-content d-flex flex-column">
                      <div class="meta d-flex align-items-center">
                        <div class="d-flex align-items-center">
                          <i class="bi bi-person"></i>
                          <span class="ps-2">{{
                            $t("investor.news.news4.author")
                          }}</span>
                        </div>
                        <!-- <span class="px-3 text-black-50">/</span>
                                                <div class="d-flex align-items-center">
                                                    <i class="bi bi-folder2"></i> <span class="ps-2">2023-05-28</span>
                                                </div> -->
                      </div>

                      <p>
                        {{ $t("investor.news.news4.title") }}
                      </p>

                      <hr />

                      <router-link
                        to="berita/4/why-invest-in-blitar"
                        class="readmore stretched-link"
                      >
                        <span>{{ $t("investor.news.news4.button") }}</span
                        ><i class="bi bi-arrow-right"></i>
                      </router-link>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6">
                  <div class="post-item position-relative h-100">
                    <div class="post-img position-relative overflow-hidden">
                      <img
                        src="assets/img/persetujuan-lahan.jpg"
                        class="img-fluid"
                        alt=""
                      />
                      <span class="post-date">{{
                        $t("investor.news.news5.date")
                      }}</span>
                    </div>

                    <div class="post-content d-flex flex-column">
                      <div class="meta d-flex align-items-center">
                        <div class="d-flex align-items-center">
                          <i class="bi bi-person"></i>
                          <span class="ps-2">{{
                            $t("investor.news.news5.author")
                          }}</span>
                        </div>
                        <!-- <span class="px-3 text-black-50">/</span>
                                                <div class="d-flex align-items-center">
                                                    <i class="bi bi-folder2"></i> <span class="ps-2">2023-05-28</span>
                                                </div> -->
                      </div>

                      <p>
                        {{ $t("investor.news.news5.title") }}
                      </p>

                      <hr />

                      <router-link
                        to="berita/5/does-indonesia-need-to-build-new-petrochemical-and-oil-refinery"
                        class="readmore stretched-link"
                      >
                        <span>{{ $t("investor.news.news5.button") }}</span
                        ><i class="bi bi-arrow-right"></i>
                      </router-link>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6">
                  <div class="post-item position-relative h-100">
                    <div class="post-img position-relative overflow-hidden">
                      <img
                        src="assets/img/persetujuan-lahan.jpg"
                        class="img-fluid"
                        alt=""
                      />
                      <span class="post-date">{{
                        $t("investor.news.news6.date")
                      }}</span>
                    </div>

                    <div class="post-content d-flex flex-column">
                      <div class="meta d-flex align-items-center">
                        <div class="d-flex align-items-center">
                          <i class="bi bi-person"></i>
                          <span class="ps-2">{{
                            $t("investor.news.news6.author")
                          }}</span>
                        </div>
                        <!-- <span class="px-3 text-black-50">/</span>
                                                <div class="d-flex align-items-center">
                                                    <i class="bi bi-folder2"></i> <span class="ps-2">2023-05-28</span>
                                                </div> -->
                      </div>

                      <p>
                        {{ $t("investor.news.news6.title") }}
                      </p>

                      <hr />

                      <router-link
                        to="berita/6/warning-to-crude-oil-producers"
                        class="readmore stretched-link"
                      >
                        <span>{{ $t("investor.news.news6.button") }}</span
                        ><i class="bi bi-arrow-right"></i>
                      </router-link>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6">
                  <div class="post-item position-relative h-100">
                    <div class="post-img position-relative overflow-hidden">
                      <img
                        src="assets/img/persetujuan-lahan.jpg"
                        class="img-fluid"
                        alt=""
                      />
                      <span class="post-date">{{
                        $t("investor.news.news7.date")
                      }}</span>
                    </div>

                    <div class="post-content d-flex flex-column">
                      <div class="meta d-flex align-items-center">
                        <div class="d-flex align-items-center">
                          <i class="bi bi-person"></i>
                          <span class="ps-2">{{
                            $t("investor.news.news7.author")
                          }}</span>
                        </div>
                      </div>

                      <p>
                        {{ $t("investor.news.news7.title") }}
                      </p>

                      <hr />

                      <router-link
                        to="berita/7/land-and-sea-conditions"
                        class="readmore stretched-link"
                      >
                        <span>{{ $t("investor.news.news7.button") }}</span
                        ><i class="bi bi-arrow-right"></i>
                      </router-link>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6">
                  <div class="post-item position-relative h-100">
                    <div class="post-img position-relative overflow-hidden">
                      <img
                        src="assets/img/persetujuan-lahan.jpg"
                        class="img-fluid"
                        alt=""
                      />
                      <span class="post-date">{{
                        $t("investor.news.news8.date")
                      }}</span>
                    </div>

                    <div class="post-content d-flex flex-column">
                      <div class="meta d-flex align-items-center">
                        <div class="d-flex align-items-center">
                          <i class="bi bi-person"></i>
                          <span class="ps-2">{{
                            $t("investor.news.news8.author")
                          }}</span>
                        </div>
                      </div>

                      <p>
                        {{ $t("investor.news.news8.title") }}
                      </p>

                      <hr />

                      <router-link
                        to="berita/8/local-people's-rights"
                        class="readmore stretched-link"
                      >
                        <span>{{ $t("investor.news.news8.button") }}</span
                        ><i class="bi bi-arrow-right"></i>
                      </router-link>
                    </div>
                  </div>
                </div>

                
              </div>
            </div>
          </section>

          <section id="kontakinvestor" class="get-started section-bg">
            <div class="container">
              <div class="row justify-content-between gy-4">
                <div class="col-lg-12 align-items-center" data-aos="fade-up">
                  <h1 class="text-bold text-dark">
                    {{ $t("investor.contact.title") }}
                  </h1>
                  <br />
                  <div class="card border-primary">
                    <div class="card-body">
                      <h5 class="mb-5">{{ $t("investor.subtitle") }}</h5>
                      <div class="row">
                        <div class="col-lg-8">
                          <a
                            class="text-dark text-bold"
                            href="https://www.google.com/maps/place/Jl.+Tanjung+No.54,+Sukorejo,+Kec.+Sukorejo,+Kota+Blitar,+Jawa+Timur+66121,+Indonesia/@-8.101593,112.152467,16z/data=!4m6!3m5!1s0x2e78ec0529b95f85:0xa88d65e72f66a10f!8m2!3d-8.1015933!4d112.152467!16s%2Fg%2F11gxq31yrh?hl=en"
                            ><i class="bi bi-pin-map"></i>
                            {{ $t("investor.contact.office1") }}</a
                          >
                          <hr />
                          <a
                            class="text-dark text-bold"
                            href="https://www.google.com/maps/search/Menteng+Square+BR+02/@-6.20044,106.851668,16z?hl=en"
                            ><i class="bi bi-pin-map"></i>
                            {{ $t("investor.contact.office2") }}</a
                          >
                          <br />
                        </div>
                        <div class="col-lg text-end pe-3 mt-5">
                          <a
                            class="text-dark text-bold"
                            href="mailto:office@blitarenergi.com"
                            ><i class="bi bi-envelope-at"> </i>
                            official@blitarenergi.com</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- End Alt Services Section -->
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
import Layout from "../layouts/MainLayout.vue";
export default {
  name: "investor",
  components: {
    Layout,
  },
  methods: {
    scrollme($section) {
      let section = document.getElementById($section);
      section.scrollIntoView({ behavior: "smooth" });
    },
  },
  mounted() {
    // var section=this.$router.currentRoute.value.hash.replace("#", "");
    // if (section)
    //     this.$nextTick(()=> window.document.getElementById(section).scrollIntoView());
  },
};
</script>
<style>
@import "/public/assets/vendor/bootstrap/css/bootstrap.min.css";
@import "/public/assets/vendor/bootstrap-icons/bootstrap-icons.css";
@import "/public/assets/vendor/fontawesome-free/css/all.min.css";
@import "/public/assets/vendor/aos/aos.css";
@import "/public/assets/vendor/swiper/swiper-bundle.min.css";
@import "/public/assets/css/main.css";
</style>
