<template>
    <div>
        <slot name="hero"></slot>
        <slot name="main"></slot>
        <Footer/>
    </div>
</template>
<script>
import Footer from '../components/Footer.vue'
export default {
    components:{
        Footer
    }
}
</script>
<style>
    
</style>