import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import VisionMission from '../pages/VisionMission.vue'
import Construction from '../pages/Construction.vue'
import GreenIndustrial from '../pages/GreenIndustrial.vue'
import Investor from '../pages/Investor.vue'
import Contact from '../pages/Contact.vue'
import Berita from '../pages/Berita.vue'
import BeritaParent from '../pages/BeritaParent.vue'
import BeritaDetail from '../pages/BeritaDetail.vue'
import Dokumentasi from '../pages/Dokumentasi.vue'
import Media from '../pages/Media.vue'
import BeritaInvestor from '../pages/BeritaInvestor.vue'
import About from '../pages/About.vue'
import KontakInvestor from '../pages/KontakInvestor.vue'
import OrganizationStucture from '../pages/OrganizationalStructure.vue'

import { i18n } from '../main.js'

const routes = [

  // {
  //   path : '/:lang',
  //   component : () => import('../components/Component.vue'),
  //   children: [
  //     {
  //       path: '/',
  //       name: 'home',
  //       component: HomeView
  //     }
  //   ]
  // },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/our-team',
    name: 'ourteam',
    component: () => import(/* webpackChunkName: "about" */ '../pages/OurTeam.vue')
  },
  {
    path:'/vision-mission',
    name: 'visionmission',
    component: VisionMission
  },
  {
    path:'/green-industrial-integrated-plant-area',
    name: 'greenindustrian',
    component: GreenIndustrial
  },
  {
    path:'/dokumentasi',
    name: 'dokumentasi',
    component: Dokumentasi
  },
  {
    path:'/dokumentasi/media/:media?',
    name: 'media',
    component: Media
  },
  {
    path:'/berita-investor',
    name: 'beritainvestor',
    component: BeritaInvestor
  },
  {
    path:'/organizational-structure',
    name: 'organizational-structure',
    component: OrganizationStucture
  },
  {
    path:'/relasi-investor',
    name: 'relasiInvestor',
    component: Investor
  },
  {
    path:'/berita',
     name: 'beritaparent',
    component: BeritaParent,
  },

  {
    path : '/berita/:id/:detail',
    name : 'beritadetail',
    component : BeritaDetail
  },
  {
    path:'/about',
    name: 'about',
    component: About
  },
  {
    path:'/kontak-hubung-investor',
    name: 'kontakhubunginvestor',
    component: KontakInvestor
  },
  {
    path:'/contacts',
    name: 'contacts',
    component: Contact
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
  // linkActiveClass: "active",
  // linkExactActiveClass: "active" 
})

router.beforeEach((to, from, next) => {
  i18n.locale = localStorage.getItem('language') || 'en'
  return next()
})

export default router
