<template>
    <div class="ourteam">
        <Layout>
            <template v-slot:hero>
                <div>
                    <section id="hero" class="hero">

                        <div class="info d-flex align-items-center">
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-lg-6 text-center">
                                        <h2 data-aos="fade-down"><span>{{ $t('so.title') }}</span></h2>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="hero-carousel" class="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">

                            <div class="carousel-item active" style="background-image: url(assets/img/bg/bg1.jpg)">
                            </div>
                            <div class="carousel-item" style="background-image: url(assets/img/bg/bg2.jpg)"></div>
                            <div class="carousel-item" style="background-image: url(assets/img/bg/bg3.jpg)"></div>
                            <div class="carousel-item" style="background-image: url(assets/img/bg/bg4.jpg)"></div>

                            <a class="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
                            </a>

                            <a class="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
                                <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
                            </a>

                        </div>

                    </section>
                </div>
            </template>

            <template v-slot:main>
                <div>
                    <section id="alt-services-2" class="alt-services section-bg">
                        <div class="container" data-aos="fade-up">

                            <div v-if="language == 'en'">
                                <!-- <div class="row justify-conten  gy-4">
                                    <div class="col-lg-12 img-bg" style="background-image: url(../assets/img/so/en.jpg); "
                                        data-aos="zoom-in" data-aos-delay="100">
                                    </div>
                                </div> -->
                                <div class="post-img text-center">
                                    <img :src="require('../assets/img/so/en.jpg')" alt="" class="img-fluid">
                                </div>
                            </div>

                            <div v-else="language">
                                <!-- <div class="row justify-content gy-4">
                                    <div class="col-lg-12 img-bg" style="background-image: url(../assets/img/so/id.jpg); "
                                        data-aos="zoom-in" data-aos-delay="100">
                                    </div>
                                </div> -->
                                <div class="post-img text-center">
                                    <img :src="require('../assets/img/so/id.jpg')" alt="" class="img-fluid">
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </template>
        </Layout>
    </div>
</template>
<script>
    import Layout from '../layouts/MainLayout.vue'
    import { mapGetters } from "vuex";
    export default {
        name: "organizationstructure",
        components: {
            Layout
        },
        data(){
            return{
                language : null,
                imgSource : null,
            }
        },
        computed:{
            ...mapGetters({
                language : 'getLanguage'
            }),
            cekLanguage(){
                return this.$store.getters.getLanguage
            }
        },
        mounted() {
            
            this.language = this.$i18n.locale
            // console.log("current language " + this.language);

            var id = this.$route.params.id
            if (this.language == 'en') {
                this.imgSource = '@/assets/img/so/en.jpg' 
            }else{
                this.imgSource = '@/assets/img/so/id.jpg' 
            }
        },

        methods:{
            setLang(language){
                if (language == 'en') {
                    this.imgSource = '@/assets/img/so/en.jpg'
                    this.language = language;
                    console.log(language);
                }else{
                    this.imgSource = '@/assets/img/so/id.jpg'
                    this.language = language;
                    console.log(language);
                }
            }
        },

        watch : {
            cekLanguage(n,o) {
                this.setLang(n)
            }
        },
    }
</script>
<style>

</style>