<template>
  <div>
    <Layout>
      <template v-slot:hero>
        <div>
          <section id="hero" class="hero">

            <div class="info d-flex align-items-center">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-6 text-center">
                    <h2 data-aos="fade-down">{{ $t('contact.title') }}</h2>

                  </div>
                </div>
              </div>
            </div>

            <div id="hero-carousel" class="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">

<div class="carousel-item active"
    style="background-image: url(assets/img/bg/bg1.jpg)">
</div>
<div class="carousel-item"
    style="background-image: url(assets/img/bg/bg2.jpg)"></div>
<div class="carousel-item"
    style="background-image: url(assets/img/bg/bg3.jpg)"></div>
<div class="carousel-item"
    style="background-image: url(assets/img/bg/bg4.jpg)"></div>

<a class="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
    <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
</a>

<a class="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
    <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
</a>

</div>

          </section>
        </div>
      </template>

      <template v-slot:main>
        <div>
          <section id="contact" class="contact">
            <div class="container" data-aos="fade-up" data-aos-delay="100">

              <div class="row gy-4">
                <div class="col-lg-6">
                  <div class="info-item  d-flex flex-column justify-content-center align-items-center">
                    <i class="bi bi-map"></i>
                    <h3>{{ $t('contact.address') }}</h3>
                    <div class="text-left">
                      <p> HOME 0FFICE, De Koloniale Building, Jl.Tanjung No. 54. Blitar–Jatim (66121)</p>
                      <p> REP. 0FFICE, Jakarta Menteng Square BR 02 Jl. Mataraman 30 E Jakarta Pusat</p>
                    </div>
                  </div>
                </div><!-- End Info Item -->

                <div class="col-lg-3 col-md-6">
                  <div class="info-item d-flex flex-column justify-content-center align-items-center">
                    <i class="bi bi-envelope"></i>
                    <h3>{{ $t('contact.email') }}</h3>
                    <p>official@blitarenergi.com</p>
                  </div>
                </div><!-- End Info Item -->

                <div class="col-lg-3 col-md-6">
                  <div class="info-item  d-flex flex-column justify-content-center align-items-center">
                    <i class="bi bi-telephone"></i>
                    <h3>{{ $t('contact.telephone') }}</h3>
                    <p>0342-8176296 / 0811871372</p>
                  </div>
                </div><!-- End Info Item -->

              </div>

              <div class="row gy-4 mt-1">

                

                <div class="col-lg-12 ">
                  <!-- <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
              frameborder="0" style="border:0; width: 100%; height: 384px;" allowfullscreen>
            </iframe> -->
                  <!-- <div class="mapouter">
                    <div class="gmap_canvas"><iframe width="100%" height="100%" id="gmap_canvas"
                        src="https://maps.google.com/maps?q=jalan tanjung  54 blitar&t=&z=19&ie=UTF8&iwloc=&output=embed"
                        frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
                        href="https://2yu.co">2yu</a><br>
                      <a href="https://embedgooglemap.2yu.co/">html embed google map</a>

                    </div>
                  </div> -->

                  <div class="mapouter">
                    
                    <div class="gmap_canvas">
                      <div class="box-sticky col-md-3 p-2">
                        <select class="form-select form-select-sm" v-model="loc" @change="onChange($e)">
                          <option value="loc1">Home Office</option>
                          <option value="loc2">Rep Office</option>
                      </select>
                      </div>
                      <div v-if="loc == 'loc1'">
                        <iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no"
                          marginheight="0" marginwidth="0"
                          src="https://maps.google.com/maps?width=1080&amp;height=512&amp;hl=en&amp;q= Jl.Tanjung No. 54 blitar&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                        </iframe>
                      </div>
                      <div v-else>
                        <iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no"
                          marginheight="0" marginwidth="0"
                          src="https://maps.google.com/maps?width=1080&amp;height=800&amp;hl=en&amp;q=Menteng Square BR 02&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                        </iframe>
                      </div>
                      <!-- <a href="https://capcuttemplate.org/">Capcut Templates</a> -->
                    </div>

                  </div>


                </div>


              </div>

            </div>
          </section>
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
    import Layout from '../layouts/MainLayout.vue'
    export default {
        name: 'contact',
        components: {
            Layout
        },
        data(){
          return {
            loc : 'loc1',
            maps : null,
          }
        },
        created () {
          this.maps = this.map1
        },
        // unmounted () {
        //   window.removeEventListener('scroll', this.handleScroll);
        // },
        methods:{
          onChange(e){
            if (this.loc == 'loc1') {
              this.maps = this.map1
            }else{
              this.maps = this.map2
            }
          }
        }
    }
</script>
<style>
 /* .mapouter {
    position: relative;
    text-align: right;
    height: 100%;
    width: 100%;
  }

  .gmap_canvas {
      overflow: hidden;
      background: none !important;
      height: 100%;
      width: 100%;
    } */

    .mapouter {
      position: relative;
      text-align: right;
      width: 100%;
      height: 800px;
    }

    .gmap_canvas {
      overflow: hidden;
      background: none !important;
      width: 100%;
      height: 800px;
      z-index: -1;
    }

    .gmap_iframe {
      height: 800px !important;
    }

    .box-sticky{
      position: sticky;
      position: -webkit-sticky;
      background: #126dc8;
      /* width: 100px;
      height: 100px; */
      width: 300px;
      height: 100px;
      top: 250px;
      left: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 6px #000;
      color: #fff;
      z-index: 100;
    }

    @media (max-width: 450px) {
      .box-sticky{
        position: sticky;
        position: -webkit-sticky;
        background: #1379e7;
        /* width: 100px;
        height: 100px; */
        top: 180px;
        left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 6px #000;
        color: #fff;
        z-index: 100;
      }
    }



                    
</style>