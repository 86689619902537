<template>
  <div>
    <Layout>
      <template v-slot:hero>
        <div>
          <section id="hero" class="hero">
            <div class="info d-flex align-items-center">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-6 text-center">
                    <h2 data-aos="fade-down">
                      {{ $t("business.green.title") }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="hero-carousel"
              class="carousel slide"
              data-bs-ride="carousel"
              data-bs-interval="5000"
            >
              <div
                class="carousel-item active"
                style="background-image: url(assets/img/bg/bg1.jpg)"
              ></div>
              <div
                class="carousel-item"
                style="background-image: url(assets/img/bg/bg2.jpg)"
              ></div>
              <div
                class="carousel-item"
                style="background-image: url(assets/img/bg/bg3.jpg)"
              ></div>
              <div
                class="carousel-item"
                style="background-image: url(assets/img/bg/bg4.jpg)"
              ></div>

              <a
                class="carousel-control-prev"
                href="#hero-carousel"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                ></span>
              </a>

              <a
                class="carousel-control-next"
                href="#hero-carousel"
                role="button"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                ></span>
              </a>
            </div>
          </section>
        </div>
      </template>

      <template v-slot:main>
        <div>
          <!-- ======= Alt Services Section 2 ======= -->
          <section id="alt-services-2" class="alt-services">
            <div class="container" data-aos="fade-up">
              <div class="section-header">
                <h2>{{ $t("business.green.desc.title") }}</h2>
                <p class="mt-3">
                  {{ $t("business.green.desc.desc") }}
                </p>
              </div>

              <div class="row justify-content-around gy-4">
                <div class="col-lg-5 d-flex flex-column justify-content-center">
                  <h3>{{ $t("business.green.intro.title") }}</h3>
                  <p>{{ $t("business.green.intro.desc") }}</p>

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <i class="bi bi-easel flex-shrink-0"></i>
                    <div>
                      <h4>
                        <a href="#land-condition" class="stretched-link">{{
                          $t("business.green.intro.sec1")
                        }}</a>
                      </h4>
                      <p>-</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <i class="bi bi-patch-check flex-shrink-0"></i>
                    <div>
                      <h4>
                        <a href="#sea-condition" class="stretched-link">{{
                          $t("business.green.intro.sec2")
                        }}</a>
                      </h4>
                      <p>-</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <h4>
                        <a href="#projects" class="stretched-link"
                          >{{ $t("business.green.intro.sec3") }}
                        </a>
                      </h4>
                      <p>-</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <h4>
                        <a href="#business-chance" class="stretched-link">{{
                          $t("business.green.intro.sec4")
                        }}</a>
                      </h4>
                      <p>-</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->
                </div>

                <div
                  class="col-lg-6 img-bg"
                  style="
                    background-image: url(assets/img/ourproject/intro2.jpg);
                  "
                  data-aos="zoom-in"
                  data-aos-delay="100"
                ></div>
              </div>
            </div>
          </section>
          <!-- End Alt Services Section 2 -->

          <!-- ======= Kondisi Tanah ======= -->
          <section id="land-condition" class="alt-services section-bg">
            <div class="container" data-aos="fade-up">
              <div class="row justify-content-around gy-4">
                <div
                  class="col-lg-6 img-bg"
                  style="background-image: url(assets/img/ourproject/land.jpg)"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                ></div>

                <div class="col-lg-5 d-flex flex-column justify-content-center">
                  <h3>{{ $t("business.green.land.title") }}</h3>
                  <p>{{ $t("business.green.land.desc") }}</p>

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <i class="bi bi-easel flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.land.sec1") }}</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <i class="bi bi-patch-check flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.land.sec2") }}</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.land.sec3") }}</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.land.sec4") }}</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.land.sec5") }}</p>
                    </div>
                  </div>

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.land.sec6") }}</p>
                    </div>
                  </div>

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.land.sec7") }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- Kondisi Laut -->
          <section id="sea-condition" class="alt-services">
            <div class="container" data-aos="fade-up">
              <div class="row justify-content-around gy-4">
                <div class="col-lg-5 d-flex flex-column justify-content-center">
                  <h3>{{ $t("business.green.sea.title") }}</h3>
                  <!-- <p>Terdiri Dari : </p> -->

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <i class="bi bi-easel flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.sea.sec1") }}</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <i class="bi bi-patch-check flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.sea.sec2") }}</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.sea.sec3") }}</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.sea.sec4") }}</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.sea.sec5") }}</p>
                    </div>
                  </div>

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.sea.sec6") }}</p>
                    </div>
                  </div>

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.sea.sec7") }}</p>
                    </div>
                  </div>

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.sea.sec8") }}</p>
                    </div>
                  </div>

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.sea.sec9") }}</p>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-6 img-bg"
                  style="background-image: url(assets/img/ourproject/sea.jpg)"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                ></div>
              </div>
            </div>
          </section>

          <!-- MAPS -->
          <section id="projects" class="projects section-bg">
            <div class="container" data-aos="fade-up">
              <div class="section-header">
                <h2>MAPS</h2>
              </div>

              <div
                class="portfolio-isotope"
                data-portfolio-filter="*"
                data-portfolio-layout="masonry"
                data-portfolio-sort="original-order"
              >
                <div
                  class="row gy-4 portfolio-container"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <img
                        src="assets/img/ourproject/map1.jpg"
                        class="img-fluid"
                        alt=""
                      />
                      <div class="portfolio-info">
                        <a
                          href="assets/img/ourproject/map1.jpg"
                          title="Remodeling 1"
                          data-gallery="portfolio-gallery-remodeling"
                          class="glightbox preview-link"
                          ><i class="bi bi-zoom-in"></i
                        ></a>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <img
                        src="assets/img/ourproject/map2.jpg"
                        class="img-fluid"
                        alt=""
                      />
                      <div class="portfolio-info">
                        <a
                          href="assets/img/ourproject/map2.jpg"
                          title="Remodeling 1"
                          data-gallery="portfolio-gallery-remodeling"
                          class="glightbox preview-link"
                          ><i class="bi bi-zoom-in"></i
                        ></a>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <img
                        src="assets/img/ourproject/map3.jpg"
                        class="img-fluid"
                        alt=""
                      />
                      <div class="portfolio-info">
                        <a
                          href="assets/img/ourproject/map3.jpg"
                          title="Remodeling 1"
                          data-gallery="portfolio-gallery-remodeling"
                          class="glightbox preview-link"
                          ><i class="bi bi-zoom-in"></i
                        ></a>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <img
                        src="assets/img/ourproject/map4.jpg"
                        class="img-fluid"
                        alt=""
                      />
                      <div class="portfolio-info">
                        <a
                          href="assets/img/ourproject/map4.jpg"
                          title="Remodeling 1"
                          data-gallery="portfolio-gallery-remodeling"
                          class="glightbox preview-link"
                          ><i class="bi bi-zoom-in"></i
                        ></a>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <img
                        src="assets/img/ourproject/map5.jpg"
                        class="img-fluid"
                        alt=""
                      />
                      <div class="portfolio-info">
                        <a
                          href="assets/img/ourproject/map5.jpg"
                          title="Remodeling 1"
                          data-gallery="portfolio-gallery-remodeling"
                          class="glightbox preview-link"
                          ><i class="bi bi-zoom-in"></i
                        ></a>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-4 col-md-6 portfolio-item filter-remodeling"
                  >
                    <div class="portfolio-content h-100">
                      <img
                        src="assets/img/ourproject/peta_garis_1.jpg"
                        class="img-fluid"
                        alt=""
                      />
                      <div class="portfolio-info">
                        <a
                          href="assets/img/ourproject/peta_garis_1.jpg"
                          title="Remodeling 1"
                          data-gallery="portfolio-gallery-remodeling"
                          class="glightbox preview-link"
                          ><i class="bi bi-zoom-in"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Projects Container -->
              </div>
            </div>
          </section>

          <!-- Peluang Bisnis -->
          <section id="business-chance" class="alt-services">
            <div class="container" data-aos="fade-up">
              <div class="section-header">
                <h2>{{ $t("business.green.peluang.title") }}</h2>
              </div>

              <!-- Kenapa Blitar -->
              <!-- <section id="about" class="about">
                <div class="container" data-aos="fade-up">
                  <div class="row position-relative">
                    <div
                      class="col-lg-7 about-img"
                      style="
                        background-image: url(assets/img/ourproject/amazing-blitar.jpg);
                      "
                    ></div>

                    <div class="col-lg-7">
                      <h2>{{ $t("business.green.peluang.blitar.sub") }}</h2>
                      <div class="our-story">
                        <ul>
                          <li>
                            <i class="bi bi-check-circle"></i>
                            <span>{{
                              $t("business.green.peluang.blitar.sec1")
                            }}</span>
                          </li>

                          <li>
                            <i class="bi bi-check-circle"></i>
                            <span>{{
                              $t("business.green.peluang.blitar.sec2")
                            }}</span>
                          </li>

                          <li>
                            <i class="bi bi-check-circle"></i>
                            <span>{{
                              $t("business.green.peluang.blitar.sec3")
                            }}</span>
                          </li>

                          <li>
                            <i class="bi bi-check-circle"></i>
                            <span>{{
                              $t("business.green.peluang.blitar.sec4")
                            }}</span>
                          </li>

                          <li>
                            <i class="bi bi-check-circle"></i>
                            <span>{{
                              $t("business.green.peluang.blitar.sec5")
                            }}</span>
                          </li>

                          <li>
                            <i class="bi bi-check-circle"></i>
                            <span>{{
                              $t("business.green.peluang.blitar.sec6")
                            }}</span>
                          </li>

                          <li>
                            <i class="bi bi-check-circle"></i>
                            <span>{{
                              $t("business.green.peluang.blitar.sec7")
                            }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section> -->
              <section id="why-blitar" class="alt-services">
                <div class="container" data-aos="fade-up">
                  <div class="row justify-content-around gy-4">
                    <div
                      class="col-lg-6 img-bg"
                      style="
                        background-image: url(assets/img/ourproject/amazing-blitar.jpg);
                      "
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    ></div>

                    <div
                      class="col-lg-5 d-flex flex-column justify-content-center"
                    >
                      <h3>{{ $t("business.green.peluang.blitar.sub") }}</h3>
                      <!-- <p>{{ $t("business.green.land.desc") }}</p> -->

                      <div
                        class="icon-box d-flex position-relative"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                        <i class="bi bi-easel flex-shrink-0"></i>
                        <div>
                          <p>{{ $t("business.green.peluang.blitar.sec1") }}</p>
                        </div>
                      </div>
                      <!-- End Icon Box -->

                      <div
                        class="icon-box d-flex position-relative"
                        data-aos="fade-up"
                        data-aos-delay="200"
                      >
                        <i class="bi bi-patch-check flex-shrink-0"></i>
                        <div>
                          <p>{{ $t("business.green.peluang.blitar.sec2") }}</p>
                        </div>
                      </div>
                      <!-- End Icon Box -->

                      <div
                        class="icon-box d-flex position-relative"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <i class="bi bi-brightness-high flex-shrink-0"></i>
                        <div>
                          <p>{{ $t("business.green.peluang.blitar.sec3") }}</p>
                        </div>
                      </div>
                      <!-- End Icon Box -->

                      <div
                        class="icon-box d-flex position-relative"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <i class="bi bi-brightness-high flex-shrink-0"></i>
                        <div>
                          <p>{{ $t("business.green.peluang.blitar.sec4") }}</p>
                        </div>
                      </div>
                      <!-- End Icon Box -->

                      <div
                        class="icon-box d-flex position-relative"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <i class="bi bi-brightness-high flex-shrink-0"></i>
                        <div>
                          <p>{{ $t("business.green.peluang.blitar.sec5") }}</p>
                        </div>
                      </div>

                      <div
                        class="icon-box d-flex position-relative"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <i class="bi bi-brightness-high flex-shrink-0"></i>
                        <div>
                          <p>{{ $t("business.green.peluang.blitar.sec6") }}</p>
                        </div>
                      </div>

                      <div
                        class="icon-box d-flex position-relative"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <i class="bi bi-brightness-high flex-shrink-0"></i>
                        <div>
                          <p>{{ $t("business.green.peluang.blitar.sec7") }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>

          <section id="choosing-strategic" class="alt-services section-bg">
            <div class="container" data-aos="fade-up">
              <div class="row justify-content-around gy-4">
                <div class="col-lg-5 d-flex flex-column justify-content-center">
                  <h3>{{ $t("business.green.industri.title") }}</h3>
                  <p>{{ $t("business.green.industri.sub") }}</p>

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <i class="bi bi-easel flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.industri.sec1") }}</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <i class="bi bi-patch-check flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.industri.sec2") }}</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.industri.sec3") }}</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.industri.sec4") }}</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->
                </div>

                <div
                  class="col-lg-6 img-bg"
                  style="
                    background-image: url(assets/img/ourproject/pabrik.jpg);
                  "
                  data-aos="zoom-in"
                  data-aos-delay="100"
                ></div>
              </div>
            </div>
          </section>

          <section id="estimation-investment" class="alt-services">
            <div class="container" data-aos="fade-up">
              <div class="row justify-content-around gy-4">
                <div
                  class="col-lg-6 img-bg"
                  style="
                    background-image: url(assets/img/ourproject/income.jpg);
                  "
                  data-aos="zoom-in"
                  data-aos-delay="100"
                ></div>

                <div class="col-lg-5 d-flex flex-column justify-content-center">
                  <h3>{{ $t("business.green.investasi.title") }}</h3>
                  <p>{{ $t("business.green.investasi.sub") }}</p>

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <i class="bi bi-easel flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.investasi.sec1") }}</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <i class="bi bi-patch-check flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.investasi.sec2") }}</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->

                  <div
                    class="icon-box d-flex position-relative"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <p>{{ $t("business.green.investasi.sec3") }}</p>
                    </div>
                  </div>
                  <!-- End Icon Box -->
                </div>
              </div>
            </div>
          </section>

        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
import Layout from "../layouts/MainLayout.vue";
export default {
  name: "greenindustrial",
  components: {
    Layout,
  },
};
</script>
<style></style>
