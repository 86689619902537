<template>
    <div>
        <div v-if="OpenClose" class="modal fade show" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Modal title</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <slot name="modal-body"></slot>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="OpenCloseFun" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "modal",
        props: {
            visible: Boolean,
            variant: String,
        },
        data() {
            return {
                OpenClose: this.visible
            }
        },
        methods: {
            OpenCloseFun() {
                this.OpenClose = false;
            },
        },
        watch: {
            visible: function (newVal, oldVal) { // watch it
                this.OpenClose = newVal;
                console.log('new' + newVal + '==' + oldVal)
            }
        }

    }
</script>
<style>

</style>