<template>
    <div>
        <Layout>
            <template>
                <div>
                    <section id="constructions" class="constructions">
                        <div class="container" data-aos="fade-up">
                            <div class="section-header">
                                <h2>Dokumentasi</h2>
                                <button class="btn btn-info" @click="OpenCloseFun()">Modal</button>
                            </div>

                           
                        </div>
                    </section>
                </div>
            </template>
        </Layout>
        
    </div>
</template>
<script>
 import Layout from '../layouts/MainLayout.vue'
 export default {
     name: "visionmission",
     components: {
         Layout
     },
     data() {
         return {
             modal: null
         }
     },
     methods: {
         showModal: function (data) {

         }
     }
 }
</script>
<style>
    
</style>