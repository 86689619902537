<template>
  <div>
    <Layout>
      <template v-slot:hero>
        <div>
          <section id="hero" class="hero">
            <div class="info d-flex align-items-center">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-6 text-center">
                    <h2 data-aos="fade-down">Berita Investor</h2>
                    <p data-aos="fade-up">
                      Berita-berita terbaru seputar PT. Blitar Putra Energy
                    </p>
                    <a
                      data-aos="fade-up"
                      data-aos-delay="200"
                      href="#kontakhubung"
                      class="btn-get-started"
                      >Kontak Hubung</a
                    >
                  </div>
                </div>
              </div>
            </div>

            <div
              id="hero-carousel"
              class="carousel slide"
              data-bs-ride="carousel"
              data-bs-interval="5000"
            >
              <div
                class="carousel-item active"
                style="background-image: url(assets/img/bg/bg1.jpg)"
              ></div>
              <div
                class="carousel-item"
                style="background-image: url(assets/img/bg/bg2.jpg)"
              ></div>
              <div
                class="carousel-item"
                style="background-image: url(assets/img/bg/bg3.jpg)"
              ></div>
              <div
                class="carousel-item"
                style="background-image: url(assets/img/bg/bg4.jpg)"
              ></div>

              <a
                class="carousel-control-prev"
                href="#hero-carousel"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                ></span>
              </a>

              <a
                class="carousel-control-next"
                href="#hero-carousel"
                role="button"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                ></span>
              </a>
            </div>
          </section>
        </div>
      </template>

      <template v-slot:main>
        <div>
          <section id="kontakhubung" class="get-started section-bg">
            <div class="container">
              <div class="row justify-content-between gy-4">
                <div class="col-lg-12 align-items-center" data-aos="fade-up">
                  <h1 class="text-bold text-dark">Kontak Hubungan Investor</h1>
                  <br />
                  <div class="card border-primary">
                    <div class="card-body">
                      <h5 class="mb-5">
                        Hubungan Investor PT. Blitar Putra Energy
                      </h5>
                      <div class="row">
                        <div class="col-lg-8">
                          <a
                            class="text-dark text-bold"
                            href="https://www.google.com/maps/place/Jl.+Tanjung+No.54,+Sukorejo,+Kec.+Sukorejo,+Kota+Blitar,+Jawa+Timur+66121,+Indonesia/@-8.101593,112.152467,16z/data=!4m6!3m5!1s0x2e78ec0529b95f85:0xa88d65e72f66a10f!8m2!3d-8.1015933!4d112.152467!16s%2Fg%2F11gxq31yrh?hl=en"
                            ><i class="bi bi-pin-map"></i> HOME 0FFICE, De
                            Koloniale Building, Jl.Tanjung No. 54. Blitar–Jatim
                            (66121)</a
                          >
                          <hr />
                          <a
                            class="text-dark text-bold"
                            href="https://www.google.com/maps/search/Menteng+Square+BR+02/@-6.20044,106.851668,16z?hl=en"
                            ><i class="bi bi-pin-map"></i> REP. 0FFICE, Jakarta
                            Menteng Square BR 02 Jl. Mataraman 30 E Jakarta
                            Pusat</a
                          >
                          <br />
                        </div>
                        <div class="col-lg text-end pe-3 mt-5">
                          <a
                            class="text-dark text-bold"
                            href="mailto:office@blitarenergi.com"
                            ><i class="bi bi-envelope-at"> </i>
                            office@blitarenergi.com</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
import Layout from "../layouts/MainLayout.vue";
export default {
  name: "visionmission",
  components: {
    Layout,
  },
};
</script>
<style></style>
