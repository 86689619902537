<template>
    <div>
        <Layout>
            <template v-slot:hero>
                <div>
                    <section id="hero" class="hero">

                        <div class="info d-flex align-items-center">
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-lg-6 text-center">
                                        <h2 data-aos="fade-down"> Construction</h2>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="hero-carousel" class="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">

                            <div class="carousel-item active"
                                style="background-image: url(assets/img/hero-carousel/hero-carousel-1.jpg)">
                            </div>
                            <div class="carousel-item"
                                style="background-image: url(assets/img/hero-carousel/hero-carousel-2.jpg)"></div>
                            <div class="carousel-item"
                                style="background-image: url(assets/img/hero-carousel/hero-carousel-3.jpg)"></div>
                            <div class="carousel-item"
                                style="background-image: url(assets/img/hero-carousel/hero-carousel-4.jpg)"></div>
                            <div class="carousel-item"
                                style="background-image: url(assets/img/hero-carousel/hero-carousel-5.jpg)"></div>

                            <a class="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
                            </a>

                            <a class="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
                                <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
                            </a>

                        </div>

                    </section>
                </div>
            </template>

            <template v-slot:main>
                <div>
                    <section id="services-cards" class="services-cards">
                        <div class="container" data-aos="fade-up">

                            <div class="row gy-4">

                                <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                    <h3>Quasi eaque omnis</h3>
                                    <p>Eius non minus autem soluta ut ui labore omnis quisquam corrupti autem odit
                                        voluptas quos commodi magnam
                                        occaecati.</p>
                                    <ul class="list-unstyled">
                                        <li><i class="bi bi-check2"></i> <span>Ullamco laboris nisi ut aliquip</span>
                                        </li>
                                        <li><i class="bi bi-check2"></i> <span>Duis aute irure dolor in
                                                reprehenderit</span></li>
                                        <li><i class="bi bi-check2"></i> <span>Ullamco laboris nisi ut aliquip ex
                                                ea</span></li>
                                    </ul>
                                </div><!-- End feature item-->

                                <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                                    <h3>Et nemo dolores consectetur</h3>
                                    <p>Ducimus ea quam et occaecati est. Temporibus in soluta labore voluptates aut. Et
                                        sit soluta non repellat
                                        sed quia dire plovers tradoria</p>

                                    <ul class="list-unstyled">
                                        <li><i class="bi bi-check2"></i> <span>Enim temporibus maiores eligendi</span>
                                        </li>
                                        <li><i class="bi bi-check2"></i> <span>Ut maxime ut quibusdam quam qui</span>
                                        </li>
                                        <li><i class="bi bi-check2"></i> <span>Officiis aspernatur in officiis</span>
                                        </li>
                                    </ul>
                                </div><!-- End feature item-->

                                <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="300">
                                    <h3>Staque laboriosam modi</h3>
                                    <p>Velit eos error et dolor omnis voluptates nobis tenetur sed enim nihil vero qui
                                        suscipit ipsum at magni.
                                        Ipsa architecto consequatur aliquam</p>
                                    <ul class="list-unstyled">
                                        <li><i class="bi bi-check2"></i> <span>Quis voluptates laboriosam numquam</span>
                                        </li>
                                        <li><i class="bi bi-check2"></i> <span>Treva libero sunt quis veniam ut</span>
                                        </li>
                                        <li><i class="bi bi-check2"></i> <span>Debitis eos est est corrupti</span></li>
                                    </ul>
                                </div><!-- End feature item-->

                                <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="400">
                                    <h3>Dignissimos suscipit iste</h3>
                                    <p>Molestiae occaecati assumenda quia saepe nobis recusandae at dicta ducimus sequi
                                        numquam commodi est in
                                        consequatur ea magnam quia itaque</p>
                                    <ul class="list-unstyled">
                                        <li><i class="bi bi-check2"></i> <span>Veritatis qui reprehenderit quis</span>
                                        </li>
                                        <li><i class="bi bi-check2"></i> <span>Accusantium vel numquam sunt minus</span>
                                        </li>
                                        <li><i class="bi bi-check2"></i> <span>Voluptatem pariatur est sationem</span>
                                        </li>
                                    </ul>
                                </div><!-- End feature item-->

                            </div>

                        </div>
                    </section><!-- End Servie Cards Section -->

                    <!-- ======= Alt Services Section 2 ======= -->
                    <section id="alt-services-2" class="alt-services section-bg">
                        <div class="container" data-aos="fade-up">

                            <div class="row justify-content-around gy-4">
                                <div class="col-lg-5 d-flex flex-column justify-content-center">
                                    <h3>Non quasi officia eum nobis et rerum epudiandae rem voluptatem</h3>
                                    <p>Maxime quia dolorum alias perspiciatis. Earum voluptatem sint at non. Ducimus
                                        maxime minima iste magni
                                        sit praesentium assumenda minus. Amet rerum saepe tempora vero.</p>

                                    <div class="icon-box d-flex position-relative" data-aos="fade-up"
                                        data-aos-delay="100">
                                        <i class="bi bi-easel flex-shrink-0"></i>
                                        <div>
                                            <h4><a href="" class="stretched-link">Lorem Ipsum</a></h4>
                                            <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias
                                                excepturi sint occaecati cupiditate
                                                non provident</p>
                                        </div>
                                    </div><!-- End Icon Box -->

                                    <div class="icon-box d-flex position-relative" data-aos="fade-up"
                                        data-aos-delay="200">
                                        <i class="bi bi-patch-check flex-shrink-0"></i>
                                        <div>
                                            <h4><a href="" class="stretched-link">Nemo Enim</a></h4>
                                            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                                                praesentium voluptatum
                                                deleniti atque</p>
                                        </div>
                                    </div><!-- End Icon Box -->

                                    <div class="icon-box d-flex position-relative" data-aos="fade-up"
                                        data-aos-delay="300">
                                        <i class="bi bi-brightness-high flex-shrink-0"></i>
                                        <div>
                                            <h4><a href="" class="stretched-link">Dine Pad</a></h4>
                                            <p>Explicabo est voluptatum asperiores consequatur magnam. Et veritatis
                                                odit. Sunt aut deserunt minus
                                                aut eligendi omnis</p>
                                        </div>
                                    </div><!-- End Icon Box -->

                                    <div class="icon-box d-flex position-relative" data-aos="fade-up"
                                        data-aos-delay="400">
                                        <i class="bi bi-brightness-high flex-shrink-0"></i>
                                        <div>
                                            <h4><a href="" class="stretched-link">Tride clov</a></h4>
                                            <p>Est voluptatem labore deleniti quis a delectus et. Saepe dolorem libero
                                                sit non aspernatur odit amet.
                                                Et eligendi</p>
                                        </div>
                                    </div><!-- End Icon Box -->
                                </div>

                                <div class="col-lg-6 img-bg"
                                    style="background-image: url(assets/img/alt-services-2.jpg);" data-aos="zoom-in"
                                    data-aos-delay="100"></div>
                            </div>

                        </div>
                    </section><!-- End Alt Services Section 2 -->

                    <!-- ======= Alt Services Section ======= -->
                    <section id="alt-services" class="alt-services">
                        <div class="container" data-aos="fade-up">

                            <div class="row justify-content-around gy-4">
                                <div class="col-lg-6 img-bg" style="background-image: url(assets/img/alt-services.jpg);"
                                    data-aos="zoom-in" data-aos-delay="100"></div>

                                <div class="col-lg-5 d-flex flex-column justify-content-center">
                                    <h3>Enim quis est voluptatibus aliquid consequatur fugiat</h3>
                                    <p>Esse voluptas cumque vel exercitationem. Reiciendis est hic accusamus. Non ipsam
                                        et sed minima temporibus
                                        laudantium. Soluta voluptate sed facere corporis dolores excepturi</p>

                                    <div class="icon-box d-flex position-relative" data-aos="fade-up"
                                        data-aos-delay="100">
                                        <i class="bi bi-easel flex-shrink-0"></i>
                                        <div>
                                            <h4><a href="" class="stretched-link">Lorem Ipsum</a></h4>
                                            <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias
                                                excepturi sint occaecati cupiditate
                                                non provident</p>
                                        </div>
                                    </div><!-- End Icon Box -->

                                    <div class="icon-box d-flex position-relative" data-aos="fade-up"
                                        data-aos-delay="200">
                                        <i class="bi bi-patch-check flex-shrink-0"></i>
                                        <div>
                                            <h4><a href="" class="stretched-link">Nemo Enim</a></h4>
                                            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                                                praesentium voluptatum
                                                deleniti atque</p>
                                        </div>
                                    </div><!-- End Icon Box -->

                                    <div class="icon-box d-flex position-relative" data-aos="fade-up"
                                        data-aos-delay="300">
                                        <i class="bi bi-brightness-high flex-shrink-0"></i>
                                        <div>
                                            <h4><a href="" class="stretched-link">Dine Pad</a></h4>
                                            <p>Explicabo est voluptatum asperiores consequatur magnam. Et veritatis
                                                odit. Sunt aut deserunt minus
                                                aut eligendi omnis</p>
                                        </div>
                                    </div><!-- End Icon Box -->

                                    <div class="icon-box d-flex position-relative" data-aos="fade-up"
                                        data-aos-delay="400">
                                        <i class="bi bi-brightness-high flex-shrink-0"></i>
                                        <div>
                                            <h4><a href="" class="stretched-link">Tride clov</a></h4>
                                            <p>Est voluptatem labore deleniti quis a delectus et. Saepe dolorem libero
                                                sit non aspernatur odit amet.
                                                Et eligendi</p>
                                        </div>
                                    </div><!-- End Icon Box -->

                                </div>
                            </div>

                        </div>
                    </section><!-- End Alt Services Section -->
                </div>
            </template>
        </Layout>
    </div>
</template>
<script>
    import Layout from '../layouts/MainLayout.vue'
    export default {
        name: 'construction',
        components: {
            Layout
        }
    }
</script>
<style>

</style>